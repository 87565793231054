import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { QuebicApiVersion } from "../../base";
import { QuebicError } from "../../types/error";
import { Client } from "../client";
import { Json2 } from "../internal/json";
import { ClientProtected } from "../internal/private";

export abstract class Controller {
	protected client: ClientProtected;

	constructor(client: Client) {
		this.client = client as unknown as ClientProtected;
	}

	protected get config(): AxiosRequestConfig {
		return {
			timeout: 10000,
			transformResponse: (res) => Json2.parse(res)
		};
	}

	protected get configAuth(): AxiosRequestConfig {
		return { ...this.config, headers: { "Authorization": `Bearer ${this.client.token}` } };
	}

	protected get base(): string {
		return this.client.endpoints[0] + QuebicApiVersion.V1;
	}

	protected async fetch<T = any>(axios: Promise<AxiosResponse<T>>): Promise<T> {
		try {
			return (await axios).data;
		} catch (e) {
			if (e instanceof Error) {
				const error = e as AxiosError;

				if (error.isAxiosError && error.response) {
					throw new QuebicError(error.response.status as number, (<AxiosResponse<{ error: string }>>error.response).data?.error);
				} else if (error.isAxiosError) {
					throw new QuebicError(-1, "network");
				} else {
					throw e;
				}
			} else {
				throw new QuebicError(-1, "network");
			}
		}
	}
}