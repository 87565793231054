import { QuebicApiVersion } from "../../base";
import { Controller } from "./controller";

export class Cdn extends Controller {
	protected get base(): string {
		// We use the cdn endpoint for this controller.
		return this.client.endpoints[3] + QuebicApiVersion.V1;
	}

	/**
	 * Creates a cdn url to an avatar image.
	 *
	 * @param {string} user_id
	 * @param {string} avatar_id
	 * @return {*}  {string}
	 * @memberof Cdn
	 */
	public avatar(user_id: string, avatar_id: string): string {
		return `${this.base}/avatars/${user_id}/${avatar_id}`;
	}

	/**
	 * Creates a cdn url to a space icon image.
	 *
	 * @param {string} space_id
	 * @param {string} icon_id
	 * @return {*}  {string}
	 * @memberof Cdn
	 */
	public spaceIcon(space_id: string, icon_id: string): string {
		return `${this.base}/space_icons/${space_id}/${icon_id}`;
	}
}