import axios from "axios";
import { QuebicInvite, QuebicInviteCreate, QuebicInviteSpace } from "../../types/invite";
import { Controller } from "./controller";

export class Invite extends Controller {
	/**
	 * Creates a new space invitation that will also allow the user
	 * access into the specified channel.
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<QuebicInvite>}
	 * @memberof Invite
	 */
	public async create(space_id: string, invite: QuebicInviteCreate): Promise<QuebicInvite> {
		return await this.fetch(axios.post(`${this.base}/space/${space_id}/invites`, invite, this.configAuth));
	}

	/**
	 * Gets an invite by it's id.
	 * (Note: more about the invite are available when you list them by their space_id)
	 *
	 * @param {string} invite_id
	 * @return {*}  {Promise<QuebicInvite>}
	 * @memberof Invite
	 */
	public async get(invite_id: string): Promise<QuebicInvite> {
		return await this.fetch(axios.get(`${this.base}/invite/${invite_id}`, this.configAuth));
	}

	/**
	 * Gets all of the invites that exist for a space.
	 * (This is used to view / delete them typically from the GUI)
	 *
	 * @param {string} space_id
	 * @return {*}  {Promise<QuebicInviteSpace>}
	 * @memberof Invite
	 */
	public async list(space_id: string): Promise<QuebicInviteSpace[]> {
		return await this.fetch(axios.get(`${this.base}/space/${space_id}/invites`, this.configAuth));
	}

	/**
	 * Attempts to join a space with the given invite_id.
	 *
	 * @param {string} invite_id
	 * @return {*}  {Promise<void>}
	 * @memberof Invite
	 */
	public async join(invite_id: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/invite/${invite_id}/join`, {}, this.configAuth));
	}

	/**
	 * Deletes an existing space invite by it's id.
	 *
	 * @param {string} invite_id
	 * @return {*}  {Promise<void>}
	 * @memberof Invite
	 */
	public async delete(invite_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/invite/${invite_id}`, this.configAuth));
	}
}