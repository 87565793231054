import { QuebicUser } from "./user";

/**
 * The required information to create a new text channel.
 *
 * @export
 * @interface QuebicChannelCreate
 */
export interface QuebicChannelCreate {
	display_name: string,
	description?: string,
	default_access_role?: number,
	is_private: boolean,
}

/**
 * The updatable attributes of a channel.
 *
 * @export
 * @interface QuebicChannelUpdate
 */
export interface QuebicChannelUpdate {
	owner_id?: string;
	display_name?: string;
	description?: string;
	default_access_role?: number;
	is_private?: boolean;
}

/**
 * The mode configuration of a channel.
 *
 * @export
 * @enum {number}
 */
export enum QuebicChannelType {
	/**
	 * A standard text channel that belongs to a Space.
	 */
	SpaceChannel = 0x0,
	/**
	 * A standard voice channel that belongs to a Space.
	 */
	SpaceVoice = 0x1,
	/**
	 * A group private message channel between 2 people.
	 */
	DirectMessage = 0x2,
	/**
	 * A group private message channel between 3-10 people.
	 */
	GroupDirectMessage = 0x3,
}

/**
 * Represents a text based communication channel.
 *
 * @export
 * @interface QuebicChannel
 */
export interface QuebicChannel {
	id: string;
	owner_id?: string;
	space_id?: string;
	icon?: string;
	type?: QuebicChannelType;
	recipients?: QuebicUser[];
	display_name?: string;
	description?: string;
	default_access_role?: number;
	user_access_role?: number;
	is_private?: boolean;
	is_encrypted?: boolean;
}