import axios from "axios";
import { QuebicMessage, QuebicMessageCreate, QuebicMessageUpdate } from "../../types/message";
import { Controller } from "./controller";

export class Message extends Controller {
	protected get base(): string {
		return `${super.base}/channel`;
	}

	/**
	 * Post a message to the specified channel.
	 *
	 * @param {string} channel_id
	 * @param {QuebicMessageCreate} message
	 * @return {*}  {Promise<QuebicMessageId>}
	 * @memberof Message
	 */
	public async create(channel_id: string, message: QuebicMessageCreate): Promise<QuebicMessage> {
		return await this.fetch(axios.post(`${this.base}/${channel_id}/messages`, message, this.configAuth));
	}

	/**
	 * List messages in a specific channel and filter around other messages.
	 * (Maximum limit is 100)
	 *
	 * @param {string} channel_id
	 * @param {number} [limit=50]
	 * @param {{ around?: string; before?: string; after?: string }} [by={}]
	 * @return {*}  {Promise<QuebicMessage[]>}
	 * @memberof Message
	 */
	public async list(channel_id: string, limit = 50, by: { around?: string; before?: string; after?: string } = {}): Promise<QuebicMessage[]> {
		return await this.fetch(axios.get(`${this.base}/${channel_id}/messages`, { ...this.configAuth, params: { ...by, limit } }));
	}

	/**
	 * Delete a message from a channel.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @return {*}  {Promise<void>}
	 * @memberof Message
	 */
	public async delete(channel_id: string, message_id: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${channel_id}/messages/${message_id}`, this.configAuth));
	}

	/**
	 * Get a single message from a channel by it's id.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @return {*}  {Promise<QuebicMessage>}
	 * @memberof Message
	 */
	public async get(channel_id: string, message_id: string): Promise<QuebicMessage> {
		return await this.fetch(axios.get(`${this.base}/${channel_id}/messages/${message_id}`, this.configAuth));
	}

	/**
	 * Update one or more attributes of a message.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @param {QuebicMessageUpdate} attributes
	 * @return {*}  {Promise<void>}
	 * @memberof Message
	 */
	public async update(channel_id: string, message_id: string, attributes: QuebicMessageUpdate): Promise<void> {
		await this.fetch(axios.patch(`${this.base}/${channel_id}/messages/${message_id}`, attributes, this.configAuth));
	}

	/**
	 * Adds an emoji reaction to the provided message.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @param {string} reaction If it's a stock emoji, this should be the friendly name "heart". If it's a custom emoji, this should be the snowflake id of the emoji.
	 * @return {*}  {Promise<void>}
	 * @memberof Message
	 */
	public async addReaction(channel_id: string, message_id: string, reaction: string): Promise<void> {
		await this.fetch(axios.put(`${this.base}/${channel_id}/messages/${message_id}/reactions`, { reaction }, this.configAuth));
	}

	/**
	 * Removes an emoji reaction from the provided message.
	 *
	 * @param {string} channel_id
	 * @param {string} message_id
	 * @param {string} reaction
	 * @return {*}  {Promise<void>}
	 * @memberof Message
	 */
	public async removeReaction(channel_id: string, message_id: string, reaction: string): Promise<void> {
		await this.fetch(axios.delete(`${this.base}/${channel_id}/messages/${message_id}/reactions`, { data: { reaction }, ...this.configAuth }));
	}
}