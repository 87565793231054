import axios from "axios";
import { QuebicOAuth2AuthorizeResponse, QuebicOAuth2Provider } from "../../types/oauth";
import { QuebicToken } from "../../types/user";
import { Controller } from "./controller";

export class OAuth extends Controller {
	/**
	 * Create a temporary OAuth2 authorization request.
	 *
	 * @param {string} provider_id
	 * @return {*}  {Promise<QuebicOAuth2AuthorizeResponse>}
	 * @memberof OAuth
	 */
	public async authorize(provider_id: string): Promise<QuebicOAuth2AuthorizeResponse> {
		return await this.fetch(axios.post(`${this.base}/user/oauth2/authorize`, { provider_id }, this.config));
	}

	/**
	 * Login using an OAuth2 authorization code.
	 *
	 * @param {string} provider_id
	 * @param {string} authorization_code
	 * @param {string} state
	 * @return {*}  {Promise<QuebicToken>}
	 * @memberof OAuth
	 */
	public async login(provider_id: string, authorization_code: string, state: string): Promise<QuebicToken> {
		return await this.fetch(axios.post(`${this.base}/user/oauth2/login`, { provider_id, authorization_code, state }, this.config));
	}

	/**
	 * Adds a supported OAuth2 provider. (Admin use only).
	 *
	 * @param {QuebicOAuth2Provider} provider
	 * @return {*}  {Promise<void>}
	 * @memberof OAuth
	 */
	public async add_provider(provider: QuebicOAuth2Provider): Promise<void> {
		await this.fetch(axios.post(`${this.base}/admin/oauth2/add_provider`, provider, this.configAuth));
	}
}