import FormData from "form-data";

/**
 * Helper routine to encode a file as 'form data'.
 *
 * @export
 * @param {(string | Blob)} file
 * @return {*}  {FormData}
 */
export function fileFormData(file: string | Blob): FormData {
	const result = new FormData();
	// We'll use this name on the server for general file uploads.
	result.append("file", file);
	return result;
}