import { QuebicMessageReaction } from "./reaction";
import { QuebicUser } from "./user";

/**
 * The possible values for a message's reference type.
 *
 * @export
 * @enum {number}
 */
export enum QuebicMessageReferenceType {
	Reply = "reply",
}

/**
 * The required information to create a new message.
 *
 * @export
 * @interface QuebicMessageCreate
 */
export interface QuebicMessageCreate {
	/**
	 * The message content or (body) that the user sees. Supports markdown format.
	 *
	 * @type {string}
	 * @memberof QuebicMessageCreate
	 */
	content: string,
	/**
	 * A message id to reference when this message is a reply.
	 *
	 * @type {string}
	 * @memberof QuebicMessageCreate
	 */
	replying_to?: string,
}

/**
 * The updatable attributes of a message.
 *
 * @export
 * @interface QuebicMessageUpdate
 */
export interface QuebicMessageUpdate {
	content: string,
}

/**
 * Represents a text/rich content message.
 *
 * @export
 * @interface QuebicMessage
 */
export interface QuebicMessage {
	id: string,
	owner?: QuebicUser,
	channel_id?: string,
	content?: string,
	pinned?: boolean,
	reactions?: QuebicMessageReaction[],
	reference_type?: QuebicMessageReferenceType,
	reference_message?: QuebicMessage,
	timestamp?: Date,
	edit_timestamp?: Date
}