import axios from "axios";
import { QuebicUsername, QuebicToken, QuebicUser } from "../../types/user";
import { fileFormData } from "../internal/formData";
import { Controller } from "./controller";

export class User extends Controller {
	protected get base(): string {
		return `${super.base}/user`;
	}

	/**
	 * Create a new user session with the given credentials.
	 *
	 * @param {string} email
	 * @param {string} password
	 * @return {*}  {QuebicToken} The user session token. You must pass this to the client in order to connect to the gateway.
	 * @memberof User
	 */
	public async login(email: string, password: string): Promise<QuebicToken> {
		return await this.fetch(axios.post(`${this.base}/login`, { email, password }, this.config));
	}

	/**
	 * Verify a new user session with the given one time password code.
	 *
	 * @param {string} token
	 * @param {string} code
	 * @return {*}  {QuebicToken} The user session token. You must pass this to the client in order to connect to the gateway.
	 * @memberof User
	 */
	public async verifyOtp(token: string, code: string): Promise<QuebicToken> {
		return await this.fetch(axios.post(`${this.base}/verify_otp`, { code }, { ...this.config, headers: { "Authorization": `Bearer ${token}` } }));
	}

	/**
	 * Creates a new authentication token using the provided refresh token.
	 * It is used for `await client.login(token)` calls to authenticate the user.
	 * (This token expires after a short amount of time)
	 *
	 * @param {string} token
	 * @return {*}  {Promise<QuebicToken>}
	 * @memberof User
	 */
	public async refresh(token: string): Promise<QuebicToken> {
		return await this.fetch(axios.post(`${this.base}/refresh`, {}, { ...this.config, headers: { "Authorization": `Bearer ${token}` } }));
	}

	/**
	 * Checks whether or not a username has been claimed.
	 *
	 * @param {string} username
	 * @return {*}  {Promise<QuebicUsername>}
	 * @memberof User
	 */
	public async checkUsername(username: string): Promise<QuebicUsername> {
		return await this.fetch(axios.post(`${this.base}/check_username`, { username }, this.config));
	}

	/**
	 * Create a new user account with the provided credentials.
	 * (The account will require email verification)
	 *
	 * @param {string} email
	 * @param {string} password
	 * @param {string} username
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async register(email: string, password: string, username: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/register`, { email, password, username }, this.config));
	}

	/**
	 * Resends a new email containing a verification code to activate
	 * your new account. (Only 3 per 30 minutes are allowed).
	 *
	 * @param {string} email
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async resendVerifyCode(email: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/resend_verify_code`, { email }, this.config));
	}

	/**
	 * Verify an existing user accounts email with the provided verification code.
	 *
	 * @param {string} email
	 * @param {string} verification_code
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async verifyEmail(email: string, verification_code: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/verify_email`, { email, verification_code }, this.config));
	}

	/**
	 * Attempt to reset the password for an account by sending an email with reset instructions.
	 *
	 * @param {string} email
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async forgotPassword(email: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/forgot_password`, { email }, this.config));
	}

	/**
	 * Attempt to reset an account password using the reset password code.
	 *
	 * @param {string} email
	 * @param {string} reset_code
	 * @param {string} password
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async resetPassword(email: string, reset_code: string, password: string): Promise<void> {
		await this.fetch(axios.post(`${this.base}/reset_password`, { email, reset_code, password }, this.config));
	}

	/**
	 * Get information about the specified user.
	 * When no user_id is provided, returns information about the current session.
	 *
	 * @param {string} user_id
	 * @return {*}  {Promise<QuebicUser>}
	 * @memberof User
	 */
	public async get(user_id?: string): Promise<QuebicUser> {
		return await this.fetch(axios.get(user_id ? `${this.base}/${user_id}` : `${this.base}`, this.configAuth));
	}

	/**
	 * Upload an avatar for a user.
	 * When no user_id is provided, it applies to the current session.
	 *
	 * @param {(string | Blob)} avatar
	 * @param {string} [user_id]
	 * @param {(test: any) => void} [onprogress]
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async uploadAvatar(avatar: string | Blob, user_id?: string, onprogress?: (event: ProgressEvent) => void): Promise<QuebicUser> {
		const data = fileFormData(avatar);
		if (process.env.IS_WEBPACK) {
			return await this.fetch(axios.post(user_id ? `${this.base}/${user_id}/avatar` : `${this.base}/avatar`, data, { ...this.configAuth, onUploadProgress: onprogress }));
		} else {
			return await this.fetch(axios.post(user_id ? `${this.base}/${user_id}/avatar` : `${this.base}/avatar`, data.getBuffer(), { ...this.configAuth, onUploadProgress: onprogress, headers: { ...this.configAuth.headers, ...data.getHeaders() } }));
		}
	}

	/**
	 * Delete an existing avatar image.
	 * When no user_id is provided, it applies to the current session.
	 * 
	 * @param {string} [user_id]
	 * @return {*}  {Promise<void>}
	 * @memberof User
	 */
	public async deleteAvatar(user_id?: string): Promise<void> {
		await this.fetch(axios.delete(user_id ? `${this.base}/${user_id}/avatar` : `${this.base}/avatar`, this.configAuth));
	}
}