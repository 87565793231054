import { QuebicSpaceMember } from "../../types/space";
import { Client } from "../client";
import { GatewayMessage } from "../internal/gateway";
import { Json2 } from "../internal/json";
import { BaseEvent } from "./baseEvent";

/**
 * Represents an event from the Quebic Gateway with member list search results.
 *
 * @export
 * @class SearchMemberListEvent
 * @extends {BaseEvent}
 */
export class SearchMemberListEvent extends BaseEvent {
	constructor(client: Client, msg: GatewayMessage<string>) {
		super(client);

		const event: { space_id: string; search: string; members: QuebicSpaceMember[]; } = Json2.parse(msg.d);

		this.space_id = event.space_id;
		this.search = event.search;
		this.members = event.members;
	}

	/**
	 * Represents the space_id of the member list this belongs to.
	 *
	 * @type {string}
	 * @memberof SearchMemberListEvent
	 */
	public readonly space_id: string;
	/**
	 * The search text that these members matched on.
	 *
	 * @type {string}
	 * @memberof SearchMemberListEvent
	 */
	public readonly search: string;
	/**
	 * A list of members that were found matching the search string.
	 *
	 * @type {QuebicSpaceMember[]}
	 * @memberof SearchMemberListEvent
	 */
	public readonly members: QuebicSpaceMember[];
}