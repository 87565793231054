export enum QuebicUrls {
	// API urls
	ApiUrl_Production = "http://127.0.0.1:3000",
	ApiUrl_Development = "https://api-staging.quebic.net",
	ApiUrl_Local = "http://127.0.0.1:3000",
	// CDN urls
	CdnUrl_Production = "http://127.0.0.1:3003",
	CdnUrl_Development = "https://cdn-staging.quebic.net",
	CdnUrl_Local = "http://127.0.0.1:3003",
	// Gateway urls
	GatewayUrl_Production = "ws://127.0.0.1:3001",
	GatewayUrl_Development = "wss://gateway-staging.quebic.net",
	GatewayUrl_Local = "ws://127.0.0.1:3001",
	// Media urls
	MediaUrl_Production = "http://127.0.0.1:3002",
	MediaUrl_Development = "https://mediaext-staging.quebic.net",
	MediaUrl_Local = "http://127.0.0.1:3002"
}

export enum QuebicApiVersion {
	V1 = "/api/v1"
}

export enum QuebicOpcodes {
	Hello = 0,
	PushEvent,
	Heartbeat,
	Reconnect,
	MemberList,
	SearchMemberList,
	Count
}

export enum QuebicWsErrorCodes {
	CloseOnUpgrade = 1006,
}