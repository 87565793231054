/* eslint-disable */

let wasm = null;
let wasmload = import("./lz4_wasm_bg.wasm").then(module => wasm = module).catch(() => wasm = null);

const lTextDecoder = typeof TextDecoder === "undefined" ? (0, module.require)("util").TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder("utf-8", { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
	if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
		cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
	}
	return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
	return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
	if (heap_next === heap.length) { heap.push(heap.length + 1); }
	const idx = heap_next;
	heap_next = heap[idx];

	heap[idx] = obj;
	return idx;
}

function getObject(idx) { return heap[idx]; }

function dropObject(idx) {
	if (idx < 36) { return; }
	heap[idx] = heap_next;
	heap_next = idx;
}

function takeObject(idx) {
	const ret = getObject(idx);
	dropObject(idx);
	return ret;
}

let WASM_VECTOR_LEN = 0;

function passArray8ToWasm0(arg, malloc) {
	const ptr = malloc(arg.length * 1);
	getUint8Memory0().set(arg, ptr / 1);
	WASM_VECTOR_LEN = arg.length;
	return ptr;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
	if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
		cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
	}
	return cachegetInt32Memory0;
}

function getArrayU8FromWasm0(ptr, len) {
	return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}
/**
* @param {Uint8Array} input
* @returns {Uint8Array}
*/
export function compress(input) {
	try {
		const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
		var ptr0 = passArray8ToWasm0(input, wasm.__wbindgen_malloc);
		var len0 = WASM_VECTOR_LEN;
		wasm.compress(retptr, ptr0, len0);
		var r0 = getInt32Memory0()[retptr / 4 + 0];
		var r1 = getInt32Memory0()[retptr / 4 + 1];
		var v1 = getArrayU8FromWasm0(r0, r1).slice();
		wasm.__wbindgen_free(r0, r1 * 1);
		return v1;
	} finally {
		wasm.__wbindgen_add_to_stack_pointer(16);
	}
}

/**
* @param {Uint8Array} input
* @returns {Uint8Array}
*/
export function decompress(input) {
	try {
		const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
		var ptr0 = passArray8ToWasm0(input, wasm.__wbindgen_malloc);
		var len0 = WASM_VECTOR_LEN;
		wasm.decompress(retptr, ptr0, len0);
		var r0 = getInt32Memory0()[retptr / 4 + 0];
		var r1 = getInt32Memory0()[retptr / 4 + 1];
		var v1 = getArrayU8FromWasm0(r0, r1).slice();
		wasm.__wbindgen_free(r0, r1 * 1);
		return v1;
	} finally {
		wasm.__wbindgen_add_to_stack_pointer(16);
	}
}

/**
 * @returns {Promise<bool>}
 */
export function isWasmSupported() {
	return new Promise((resolve) => {
		wasmload.then(() => resolve(wasm != null)).catch(() => resolve(false));
	});
}

export function __wbindgen_string_new(arg0, arg1) {
	var ret = getStringFromWasm0(arg0, arg1);
	return addHeapObject(ret);
}

export function __wbindgen_rethrow(arg0) {
	throw takeObject(arg0);
}

